<template>
  <div class="one-column-width">
    <v-card class="add-form-title">
      <v-card-title> Edit Listing </v-card-title>
      <div>
        <v-form ref="form" @submit.prevent="submit" class="custom-form">
          <v-container fluid>
            <v-col cols="12" sm="12">
              <v-row>
                <v-text-field
                  v-model="title"
                  :rules="rules.title"
                  color="purple name-2"
                  label="Listing Name"
                  required
                  style="width: 100%; max-width: 100% !important"
                ></v-text-field>

                <v-autocomplete
                  v-model="campus_id"
                  :rules="rules.campus_id"
                  :items="campusesData"
                  label="Select Campus"
                  class="ml-4"
                  @change="filterDepartment($event)"
                ></v-autocomplete>
                <v-autocomplete
                  v-model="department_id"
                  :rules="rules.department_id"
                  :items="departmentsData"
                  label="Select Department"
                  class="ml-2"
                  @change="filterProgram($event)"
                ></v-autocomplete>
                <v-autocomplete
                  v-model="program_id"
                  :rules="rules.program_id"
                  :items="programsData"
                  label="Select Program"
                  class="ml-2"
                ></v-autocomplete>

                <v-autocomplete
                  :items="firstLevelCategoriesData"
                  label="First Level category"
                  class="ml-4"
                  @change="filterSecondLevelCatigories($event)"
                ></v-autocomplete>
                <v-autocomplete
                  :items="secondLevelCategories"
                  label="Second Level category"
                  class="ml-2"
                  @change="filterCatigories($event)"
                ></v-autocomplete>
                <v-autocomplete
                  v-model="category_id"
                  :rules="rules.category_id"
                  :items="Categories"
                  label="Main categories"
                  class="ml-2"
                ></v-autocomplete>
                <div class="row" style="margin-top: 2%">
                  <div class="col-md-12">
                    <vue-editor
                      v-model="description"
                      outlined
                      name="input-7-4"
                      label="About"
                      rows="3"
                    ></vue-editor>
                  </div>
                </div>

                <div class="col-md-3">
                  <img
                    style="border-radius: 8px; width: 126px; height: 126px"
                    :src="url"
                  />
                  <v-file-input
                    style="font-size: 8px"
                    label="Main Banner"
                    @change="Preview_image"
                    v-model="image"
                    :rules="rules.image"
                  >
                  </v-file-input>
                </div>
                <div class="col-md-3">
                  <img
                    style="border-radius: 8px; width: 126px; height: 126px"
                    :src="url2"
                  />
                  <v-file-input
                    style="font-size: 8px"
                    label="Second Image"
                    @change="Preview_image_2"
                    v-model="image2"
                  >
                  </v-file-input>
                </div>
                <div class="col-md-3">
                  <v-file-input
                    style="font-size: 8px; margin-top: 130px"
                    label="Brochure"
                    @change="pdf()"
                    v-model="brochure"
                  >
                  </v-file-input>
                </div>
                <div class="col-md-3">
                  <v-autocomplete
                    v-model="admission_form_id"
                    :items="admissionForms"
                    label="Admission Form"
                    class="ml-3"
                    style="margin-top: 130px"
                  ></v-autocomplete>
                </div>
                <!-- <template v-if="!editId">
                  <v-btn
                    class="custom-add-new-record-button"
                    type="button"
                    @click="addNewRow()"
                    >+</v-btn
                  >
                  <v-btn
                    v-if="index > 0"
                    class="custom-delete-new-record-button"
                    type="button"
                    @click="deleteRow(index)"
                    >-</v-btn
                  >
                </template> -->
              </v-row>
            </v-col>
          </v-container>
          <v-card-actions>
            <v-btn class="custom-submit-button" type="submit"
              ><b-spinner small type="grow" v-if="submitting"></b-spinner>
              <span v-if="!editId">Submit</span>
              <span v-if="editId">Update</span>
            </v-btn>
            <v-btn text @click="cancelForm" class="custom-cancel-button"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-form>
      </div>
    </v-card>
  </div>
</template>

<script>
import ProgramService from "@/MainServices/ProgramService.js";
import SubjectService from "@/MainServices/SubjectService.js";
import ListingService from "@/MainServices/ListingService.js";
import CategoryService from "@/MainServices/CategoryService.js";
import { VueEditor } from "vue2-editor";
import API from "@/core/api";

import { required, minLength } from "vuelidate/lib/validators";
export default {
  data() {
    return {
        api: API.API_HOME,
      title: "",
      campus_id: null,
      department_id: null,
      program_id: null,
      category_id: null,
      image: null,
      image2: null,
      brochure: null,
      admission_form_id: null,
      description: null,
      campusesData: [],
      departmentsData: [],
      programsData: [],
      departments: [],
      programs: [],
      admissionForms: [],
      url: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",
      url2: "https://cdn.pixabay.com/photo/2016/08/08/09/17/avatar-1577909_960_720.png",

      firstLevelCategoriesData: [],
      secondLevelCategoriesData: [],
      CategoriesData: [],
      secondLevelCategories: [],
      Categories: [],
      editId: null,
      deleteId: null,
      dialog: false,
      rules: {
        title: [(val) => (val || "").length > 0 || "Title is required"],
        campus_id: [(val) => !!val || "Campus is required"],
        department_id: [(val) => !!val || "Department is required"],
        program_id: [(val) => !!val || "Program is required"],
        category_id: [(val) => !!val || "Category is required"],
        image: [(val) => !!val || "Image is required"],
        description: [
          (val) => (val || "").length > 0 || "Description is required",
        ],
      },
      snackbar: false,
      snackBarText: null,
      submitting: false,
      deleting: false,
      errorMessage: false,
      search: "",
      showFormData: false,
      headers: [
        {
          text: "Campus",
          align: "left",
          sortable: false,
          value: "campus",
          width: "20%",
        },
        {
          text: "Department",
          align: "left",
          sortable: false,
          value: "department",
          width: "18%",
        },

        {
          text: "Program",
          align: "left",
          sortable: false,
          value: "program",
          width: "15%",
        },
        {
          text: "Title",
          align: "left",
          sortable: false,
          value: "title",
          width: "20%",
        },
        {
          text: "Plan",
          align: "left",
          sortable: false,
          value: "stripe_plan",
          width: "10%",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      tableData: [],
      data: JSON.parse(localStorage.getItem("listing")),
    };
  },
  validations: {
    form: {
      required,
      $each: {
        title: { minLength: minLength(1), required },
        campus_id: { minLength: minLength(1), required },
        department_id: { minLength: minLength(1), required },
        program_id: { minLength: minLength(1), required },
        category_id: { minLength: minLength(1), required },
        image: { minLength: minLength(1), required },
        description: { minLength: minLength(1), required },
      },
    },
  },
  components: {
    VueEditor,
  },

  computed: {},

  methods: {
    getInititalData() {
      // console.log(this.$route.params.item);
      const { title, campus } = this.data;
      ListingService.getInstituteListings()
        .then((res) => {
          this.tableData = res.data;
          this.title = title;
          this.campus_id = campus;
        })

        .catch(() => {
          this.loading = false;
        });
    },
    // main methods
    Preview_image() {
      this.url = URL.createObjectURL(this.image);
    },
    Preview_image_2() {
      this.url2 = URL.createObjectURL(this.image2);
    },

    filterDepartment(id) {
      this.departments = this.departmentsData.filter((el) => {
        return el.campus_id == id;
      });
    },
    filterProgram(id) {
      this.programs = this.programsData.filter((el) => {
        return el.department_id == id;
      });
    },
    filterSecondLevelCatigories(id) {
      this.secondLevelCategories = this.secondLevelCategoriesData.filter(
        (el) => {
          return el.parentId == id;
        }
      );
    },
    filterCatigories(id) {
      this.Categories = this.CategoriesData.filter((el) => {
        return el.parentId == id;
      });
    },
    redirectToAddListing() {
      this.$router.push("/institute/add-listing");
    },
    showForm() {
      this.editId = null;
      this.showFormData = true;
      this.resetForm();
    },
    showEditForm(item) {
      this.form = [{ name: item.subject, program_id: item.program_id }];
      this.editId = item.id;
      this.showFormData = true;
    },
    cancelForm() {
      this.resetForm();

      this.showFormData = false;
    },
    resetForm() {
      this.form = [{ city: "", country_id: null }];
      this.editId = null;
      setTimeout(() => {
        this.$refs.form.reset();
      }, 100);
    },
    addNewRow() {
      this.form.push({ city: "", country_id: null });
    },
    deleteRow(index) {
      this.form.splice(index, 1);
    },
    submit() {
      this.submitting = true;
      let formData = new FormData();
      formData.append("title", this.title);
      formData.append("listing_id", this.data.id);
      formData.append("campus_id", this.campus_id);
      formData.append("department_id", this.department_id);
      formData.append("program_id", this.program_id);
      formData.append("category_id", this.category_id);
      formData.append("image", this.image);
      formData.append("image2", this.image2);
      formData.append("brochure", this.brochure);
      formData.append("admission_form_id", this.admission_form_id);
      formData.append("description", this.description);

      ListingService.updateNewListing(formData)
        .then((res) => {
          this.resetForm();
          this.getInititalData();
          this.submitting = false;
          this.snackBarText = "New listing Added!";
          this.snackbar = true;
          this.$router.push("/institute/listings");
        })
        .catch((error) => {
          this.errorMessage = true;
        });

      // if (this.editId) {
      //   SubjectService.editSubject(this.form, this.editId)
      //     .then((res) => {
      //       this.resetForm();
      //       this.getInititalData();
      //       this.submitting = false;
      //       this.snackBarText = "City Updated successful!";
      //       this.snackbar = true;
      //     })
      //     .catch((error) => {
      //       this.errorMessage = true;
      //     });
      // } else {
      //   SubjectService.addNewSubjects(this.form)
      //     .then((res) => {
      //       this.resetForm();
      //       this.getInititalData();
      //       this.submitting = false;
      //       this.snackBarText = "Subject Added successful!";
      //       this.snackbar = true;
      //     })
      //     .catch((error) => {
      //       this.errorMessage = true;
      //     });
      // }
    },
    askUserAboutDelete(item) {
      this.deleteId = item.id;
      this.dialog = true;
    },
    deleteItem() {
      this.deleting = true;
      SubjectService.deleteSubject(this.deleteId)
        .then((res) => {
          this.editId = null;
          this.deleting = false;
          this.dialog = false;
          this.resetForm();
          this.getInititalData();
          this.snackBarText = "Subject Deleted successful!";
          this.snackbar = true;
        })
        .catch((error) => {
          this.errorMessage = true;
          this.dialog = false;
        });
    },
  },
  mounted() {
    this.getInititalData();
    ProgramService.getInstitutePrograms()
      .then((res) => {
        this.description = this.data.description;
        this.url = this.api+'/storage' + '/'+this.data.images[0].image_path;
        this.url2 = this.api+'/storage' + '/'+this.data.images[1].image_path;
        res.data.map((el) => {
          this.campusesData.push({ value: el.id, text: el.name });
          res.data.forEach((element) => {
            if (element.name == this.data.campus) {
              this.campus_id = element.id;
            }
          });
          el.departments.map((el2) => {
            this.departmentsData.push({
              value: el2.id,
              text: el2.department,
              campus_id: el.id,
            });
            el.departments.forEach((element) => {
              if (element.department == this.data.department) {
                this.department_id = element.id;
              }
            });
            el2.programs.map((el3) => {
              this.programsData.push({
                value: el3.id,
                text: el3.program,
                department_id: el3.department_id,
              });
            });
            el2.programs.forEach((element) => {
              if (element.program == this.data.program) {
                this.program_id = element.id;
              }
            });
          });
        });
      })
      .catch(() => {
        this.loading = false;
      });
    CategoryService.getFirstLevelCategories()
      .then((res) => {
        res.data.map((el) => {
          this.firstLevelCategoriesData.push({
            value: el.id,
            text: el.category,
          });
        });
      })
      .catch((e) => {});
    CategoryService.getSecondLevelCategories()
      .then((res) => {
        res.data.map((el) => {
          this.secondLevelCategoriesData.push({
            value: el.id,
            text: el.category,
            parentId: el.first_level_categories_id,
          });
        });
      })
      .catch((e) => {});
    CategoryService.getCategories()
      .then((res) => {
        res.data.map((el) => {
          this.CategoriesData.push({
            value: el.id,
            text: el.category,
            parentId: el.second_level_categories_id,
          });
        });
      })
      .catch(() => {
        this.loading = false;
      });
  },
};
</script>
